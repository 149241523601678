// ==========================================================================
// Archive Styles
// ==========================================================================

.archive {    
    .header {
        padding-top: $spacer * 2.5;
        padding-bottom: $spacer * 2.5;        
        
        .container {
            @include break-from( medium ) {
                @include flex-container( $align: center );
            }

            h2 {
                padding: 0 0 $spacer;
                margin: 0;            
            }           
        }
    }

    .category {        
        padding-bottom: $spacer * 2.5;

        .container {            
            @include flex-container( $align: center );            
            
            .col {
                margin-bottom: $spacer * .5;
                flex-grow: 0;

                .btn {
                    min-width: 150px;
                    text-align: center; 
                }
            }
        }
    }

    #spinner-ajax {
        display: none;
        text-align: center;
    }

    #blog-archive {
        padding-bottom: $spacer * 2.5;
        
        .grid-wrapper > a { 
            display: flex;
            justify-content: center;
            align-items: center;            
        }
        .grid-wrapper > a > img {
            width: 100%;
            height: 100%;
            object-fit: cover; 
        }
        .grid-wrapper {
            display: grid;
            grid-gap: $spacer;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            grid-auto-rows: auto;
            grid-auto-flow: dense;
        }
        .grid-wrapper a.normal {
            grid-column: span 2;    

            @include break-from( medium ) { 
                grid-column: span 1;            
            }
        }
        .grid-wrapper a.wide {
            grid-column: span 2;

            h2 {                
                font-size: $h2-font-size * .8;
                width: 280px;                
            }         
        }
        .grid-wrapper a.tall {
            grid-row: span 2;
        }
        .grid-wrapper a.big {
            grid-column: span 2;
            grid-row: span 2;

            h2 {                
                font-size: $h2-font-size * .8;
                width: 280px;                                           
            }            
        } 

        .grid-wrapper a {
            position: relative;
            transition: 0.3s;
            
            &:hover {
                .overlay {
                    color: $white;         
                    opacity: 1;                                
                }
            }

            .overlay {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                height: 100%;
                width: 100%;
                opacity: 0;
                transition: .5s ease;
                background-color: rgba(155,155,155,0.8); 
                
                h2 {
                    color: $white;                            
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    line-height: 80%; 
                    -webkit-transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    text-align: center;                            
                    text-transform: uppercase;
                    font-family: 'TT-Bluescreens';
                    font-size: $h2-font-size * .8;
                    text-align: left;
                    
                    @include break-from( medium ) {
                        font-size: $h2-font-size * .7;
                    }
                }
                
                span {
                    font-size: $font-size-base * 1.2;       
                    text-transform: capitalize;            
                    font-family: Assistant; 
                }
            }    
        }
    }    
}
 
.navigation {
    &.pagination {
        a {
            color: $black;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
} 