// Variables
//

// Font face
@font-face {
    font-family: 'TT-Bluescreens';
    src: url('../fonts/TTBluescreens-Bold.eot');
    src: url('../fonts/TTBluescreens-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TTBluescreens-Bold.woff2') format('woff2'),
        url('../fonts/TTBluescreens-Bold.woff') format('woff'),
        url('../fonts/TTBluescreens-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Assistant';
    src: url('../fonts/Assistant-Regular.eot');
    src: url('../fonts/Assistant-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Assistant-Regular.woff2') format('woff2'),
        url('../fonts/Assistant-Regular.woff') format('woff'),
        url('../fonts/Assistant-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Hertine';
    src: url('../fonts/Hertine.eot');
    src: url('../fonts/Hertine.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Hertine.woff2') format('woff2'),
        url('../fonts/Hertine.woff') format('woff'),
        url('../fonts/Hertine.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

// colors
$black:         #2a2a2a;
$light-blue:    #afc0d7;
$brown:         #aa8575;
$red:           #ec4c4c; 
$gray-150:		#9b9b9b;
$gray:          #f1eff0;

$link-color:    $brown;

// sizes
$breakpoints: (
	xxsmall: 0,
	xsmall:  576px,
	small:   768px,
	medium:  810px,
	large:   1080px,
	xlarge:  1440px,
	xxlarge: 1600px,
);