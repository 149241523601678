// ==========================================================================
// Footer Styles
// ==========================================================================
#sb_instagram #sbi_images {
	@include break-until( medium ) {
		padding: $spacer / 4 !important;
	}
}

#sb_instagram.sbi_col_10 #sbi_images .sbi_item,
#sb_instagram.sbi_col_3 #sbi_images .sbi_item,
#sb_instagram.sbi_col_4 #sbi_images .sbi_item,
#sb_instagram.sbi_col_5 #sbi_images .sbi_item,
#sb_instagram.sbi_col_6 #sbi_images .sbi_item,
#sb_instagram.sbi_col_7 #sbi_images .sbi_item,
#sb_instagram.sbi_col_8 #sbi_images .sbi_item,
#sb_instagram.sbi_col_9 #sbi_images .sbi_item {
	width: 100 / 3 * 1%;

	@include break-from( small ) {
		width: 100 / 4 * 1%;
	}
}

.instagram-wrapper {
    padding-top: $spacer * 2.5;
    padding-bottom: $spacer * 2.5;    

	@include flex-container( $direction: column );

	@include break-from( small ) {		
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	.instagram {		
	
		h2 {
			margin: 0;			
            text-transform: uppercase;
            font-size: $h1-font-size * .6;
            padding-bottom: $spacer * .5;
		}

		a {
			display: inline-block;
			font-weight: $font-weight-bolder;
			padding-bottom: $spacer / 4;
            color: $black;

			img.icon {
				width: 20px;
				stroke: $primary;
				fill: red;
			}
		}
	}

	.instagram-posts {
		width: 100%;		
	}
}


.newsletter {
    background: $light-blue;
    padding-top: $spacer * 2.5;
    padding-bottom: $spacer * 2.5;
    
     .container {
        @include break-from( medium ) {
            @include flex-container( $align: center );
        }

        .col1 {            
            @include flex-item($grow: 2);  

            h5 {
                color: $white;
                line-height: 120%;
            }
        }

        .col2 {           
            @include flex-item($grow: 10);  
            .gform_wrapper {
                form {                    
                    @include break-from( medium ) {
                        @include flex-container( $align: center );
                    }

                    .gform_body {
                        @include flex-item($grow: 9);  

                        li {
                            margin: 0;                            
                            label {
                                display: none;
                            }
                        }                        
                    }

                    .gform_footer {
                        margin: 0;
                        padding: 12px 0 10px;
                        @include flex-item($grow: 3);  

                        input[type="submit"] {
                            width: 100%;         
                            margin-top: 1px;                   
                        }
                    }   
                }

                input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]) {
                    padding: 1em;
                    border: 2.5px solid $white;
                }                
            } 
        }
     }
    
}

footer {
    background: $brown;
    padding-top: $spacer;
    padding-bottom: $spacer;
    color: $white;

    .container {        
        @include break-from( medium ) {
            @include flex-container( $align: flex-start );    
        }

         a {
            color: $white;
        }

        .col {
            @include flex-item();        
            
            &.logo {
                flex-grow: 5;    
            }

            &.links {
                flex-grow: 2;  
            }

            &.brand {
                flex-grow: 3;   
                
                .content {
                    a {
                        &:nth-of-type(1) {
                            padding-right: $spacer;
                        }
                    }
                }
            }

            &.social-media{
                flex-grow: 2;    
            }

            h2 {
                font-size: $font-size-base;
                color: $black;
                text-transform: uppercase;
                padding-top: 4px;
            }

            .content {
                min-height: 75px; 
            }
        }

        .logo {
            position: relative;

            img {               
                top: $spacer * .5; 
            }
        }

        .sitelinks {
            .menu {
                margin-bottom: 0;
                margin-top: 0;
                padding: 0 0 0 0;
                @include break-from( medium ) {
                    padding: 0 0 0 $spacer;
                }                

                li {
                    list-style: none;    
                    
                    a {
                        color: $black;
                        font-size: $font-size-base * 1.8;
                        text-transform: uppercase;
                        line-height: 120%;
                        font-family: 'TT-Bluescreens'; 
                    }
                }
            }
        }

        .copyright {
            font-size: $font-size-base * .9;
            padding-top: .3em;
        }

        .social-media {

            a {
                svg.fa-facebook-f,
                svg.fa-instagram {
                    color: $brown;                
                }

                span.fa-stack {
                    margin-right: $spacer * .3;
                }

                &:hover {
                    text-decoration: none;
                }
            }           

            ul {
                padding: 0;
                margin: 0;

                li {
                    list-style: none;
                    display: inline-block;
    
                    a {
                        font-size: $font-size-base * .9;
                    }
                }
            }            
        }        
    }
}