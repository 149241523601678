// ==========================================================================
// Global Styles
// ==========================================================================

*,
*:focus,
*:hover {
    outline:none;
}

// Heading
h1,
h2,
h3,
h4 {
	font-family: 'TT-Bluescreens';
}

h5 {
	font-family: 'Hertine';
}

h1 {
	font-size: $h1-font-size  * .5;

	@include break-from( medium ) {
		font-size: $h1-font-size;
	}
} 

h2 {
	font-size: $h2-font-size  * .6;

	@include break-from( medium ) {
		font-size: $h1-font-size;
	}
} 

// icon-new 
.new-soup {
	h2 {
		background: $red;
		display: inline-block;
		color: $white;
		bottom: 0em;
		font-weight: 700;	
		position: absolute;				
		border-radius: 46px;
		height: 60px;
		width: 60px;
		padding: 20px 17px 0;		
		font-size: 23px;		
		right: .2em;

		@include break-from( medium ) {
			border-radius: 100px;
			height: 100px;
			width: 100px;
			padding: 35px 29px 0;		
			font-size: 36px;				
			right: 1em;
		}		
	}
}

// link
a {
	color: $link-color;	

	&:hover {
		color: $link-color;	
	}
}

// list
.content {
	ol, 
	ul {
		padding-left: $spacer;
	
		li {
			padding-top: $spacer * .3;			
			padding-left: 0;
		}
	}
}


// Button
.btn {
	outline: 0;

	&:focus {
		box-shadow: 0 0 0 0;
	}
	
	&.btn-primary {
		border: 1px solid $black;
		border-radius: 0;
		background: transparent;
		color: $black; 
		padding: 1em 3em;		

		&:hover {
			border: 1px solid $black;
			background: $black;
			color: $white; 
		}

		&.white {
			border: 1px solid $white;
			color: $white;

			&:hover {
				border: 1px solid $white;
				background: $white;
				color: $black; 
			}
		}
	}

	&.btn-secondary {
		border: 1px solid $white;
		border-radius: 0;
		background: transparent;
		color: $white; 
		padding: 1em 3em;

		&:hover {
			border: 1px solid $black;
			background: $black;
			color: $white;
		}
	}

	&.btn-thrid {
		border: 1px solid $gray-150;
		border-radius: 0;
		background: transparent;
		color: $black; 
		padding: .8em 3em;
		border-radius: 4em;

		&:hover {
			border: 1px solid $gray-150;
			background: $gray-150;
			color: $white;
		}
	}
} 



// Gravity Form
.gform_wrapper {
	.validation_message {
		display: none;
	}
	
	ul {
		padding: 0;
		margin: 0;

		li.gfield.gfield_error {
			margin: 0 !important;
			padding-top: 0;
			padding-right: 8px !important ;
		}

		li.gfield {
			margin: 0;
			padding-top: 0;
			padding-left: 0;
			padding-right: 0;
			padding-bottom: 5px;
		}
	} 

	.top_label {	
		.gfield_label {
			display: none !important;
		}
	} 

	input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]) {
		padding: .8em 4px;
	}

	.gform_footer {
		text-align: center;
		
		input.button,
		input[type="submit"] {
			border: 1px solid $black;
			border-radius: 0;
			background: transparent;
			color: $black; 
			padding: 1.05em 3em 1.08em;

			&:hover {
				border: 1px solid $black;
				background: $black;
				color: $white; 
			}
		}
	}
}

// FAQ
.accordion {
	.card {
		border-radius: 0;
		border-bottom: 0;
		border-left: 0;
		border-right: 0;


		.card-header {
			background-color: transparent;
			padding: 0;

			h2 {
				padding: 0;

				.btn {
					padding: 0;
					border-radius: 0; 
					outline: 0;
				}
			}
		}
	}
}

/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
	margin: 0 $spacer 0 0;
}

.aligncenter,
div.aligncenter {
	display: inline-block;
	margin: 5px auto 5px $spacer;
}

.alignright {
	float: right;
	margin: 5px 0 $spacer $spacer;
}

.alignleft {
	float: left;
	margin: 5px ($spacer * 1.3) $spacer 0;
}

a img.alignright {
	float: right;
	margin: 5px 0 $spacer $spacer;
}

a img.alignnone {
	margin: 5px $spacer $spacer 0;
}

a img.alignleft {
	float: left;
	margin: 5px $spacer $spacer 0;
} 

a img.aligncenter { 
	display: inline-block;
	margin-left: auto;
	margin-right: $spacer;	
}

.wp-caption {
	background: #fff;
	border: 1px solid #f0f0f0;
	max-width: 96%; /* Image does not overflow the content area */
	padding: 5px 3px 10px;
	text-align: center;
}

.wp-caption.alignnone {
	margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
	margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
	margin: 5px 0 20px 20px;
}

.wp-caption img {
	border: 0 none;
	height: auto;
	margin: 0;
	max-width: 98.5%;
	padding: 0;
	width: auto;
}

.wp-caption p.wp-caption-text {
	font-size: 11px;
	line-height: 17px;
	margin: 0;
	padding: 0 4px 5px;
}

/* Text meant only for screen readers. */
.screen-reader-text {
	border: 0;
	clip: rect( 1px, 1px, 1px, 1px );
	clip-path: inset( 50% );
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
	background-color: #eee;
	clip: auto !important;
	clip-path: none;
	color: #444;
	display: block;
	font-size: 1em;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000;

	/* Above WP toolbar. */
}

body .gform_wrapper div.ginput_container.ginput_recaptcha {  
	padding-top: 0 !important; 
}