// A just right amount of clean but powerful toolkit
@import '../../node_modules/genetiks/utilities';
@import 'variables';
@import 'bootstrap-custom';

html {
	// Use percentage to inherit the value from the user's browser settings
	font-size: 100%;
}

// Slick
@import 'slick/slick-theme';
@import 'slick/slick';
 
@import 'layouts/global';
@import 'layouts/header';
@import 'layouts/navigation';
@import 'layouts/content';
@import 'layouts/soup-archive';
@import 'layouts/soup-single';
@import 'layouts/archive';
@import 'layouts/single';
@import 'layouts/footer';
