// ==========================================================================
// Navigation Styles
// ==========================================================================

.navbar {
	justify-content: flex-end;
	padding: 0;

	.menu { 
		margin-bottom: 0;
		padding-left: 0;
		list-style-type: none;
		justify-content: center;

		> li {
			padding: $spacer 0;
			display: block;

			@include break-from( medium ) {
				margin: 0 1rem;
			}

			&:first-child {
				margin-left: 0;
			}

			> a {
				font-weight: 500;
				color: $black;
				transition: 0.3s;
				position: relative;
				padding: $spacer / 6 0 0;
				border-bottom: 0;
				font-weight: bold;
			}

			
		}

		&.inline {
			li {
				display: inline-block;
				margin-right: $spacer;
				margin-bottom: 0;

				&:last-child {
					margin-right: 0;
				}
			}
		}

		> .has-sub {
			position: relative;

			li {
				padding-left: 0;
				margin-left: 0;

				a {
					color: $black;
					text-align: left;			
					font-weight: bold;		

					&:hover {
						text-decoration: underline;
					}
				}

				&.active {
					a {
						&::before {
							content: ' ';
							border-bottom: 0;
						}
					}
				}
			}

			svg {
				font-size: $font-size-base * .7;
				color: $black;
				top: 0;
				bottom: 0;
				margin: auto 0 auto 4px;

				@include break-until( medium ) {
					font-size: 1.2em;
					margin-left: 12px;
				}
			}

			&::after {
				display: inline-block;
			    font-style: normal;
			    font-variant: normal;
			    text-rendering: auto;
			    -webkit-font-smoothing: antialiased;
			    font-family: "Font Awesome 5 Free";
			    font-weight: 900;
			    content: "\f078";
			    color: $white;
			    font-size: 0.9em;
			    position: relative;
			    right: -5px;
				display: none;

				@include break-until( medium ) {
					font-size: 0.9em;
				}
			} 

			&:hover,
			&:active,
			&:focus {
				> .sub-menu {
					opacity: 1;
					transform: scale( 1, 1 );
					background: $white;
					-webkit-box-shadow: 0 3px 3px 0px rgba(0,0,0,.1);
            		box-shadow: 0 3px 3px 0px rgba(0,0,0,.1);

					@include break-until( medium ) {
						position: relative;
						width: 100%;
					}

					li {
						visibility: visible;
					}
				}

				@include break-until( medium ) {
					svg {
						display: none;
					}
				}
			}
		}
	}

	.sub-menu {
		margin: $spacer 0 0 (-$spacer / 2);
		padding: 0 1.25rem 1.25rem;
		list-style: none;
		opacity: 0;
		transform: scale( 1, 0 );
		transform-origin: top;
		transition: opacity 0.1s, transform 0 linear;
		position: absolute;
		z-index: 1000;
		background: $white;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;

		li {
			visibility: hidden;
			width: 100%;
			margin-right: 0;
			padding-right: 0;
		}

		a {
			color: $white;
			white-space: nowrap;
			display: block;
			padding: ($spacer / 12) ($spacer / 2);			        	
		}
	}
}