// ==========================================================================
// Single Styles
// ==========================================================================
.content-post {
    padding-top: $spacer * 2.5;
    padding-bottom: $spacer * 2.5;

    .content {        
        article {
            @include break-from( medium ) {
                @include flex-container( $align: stretch ); 
            }

            .entry-featured,
            .entry-wrap {
                @include flex-item();              
            }

            .entry-wrap { 
                padding-left: $spacer * 2;
                margin-top: $spacer;
                margin-bottom: $spacer;

                h5 {
                    font-size: $font-size-base * 2;                    
                    padding-bottom: $spacer * .5;
                }

                h2 {
                    text-transform: uppercase;
                }
            }

            .entry-featured {
                background-size: cover;
                background-position: center center;
                text-align: center;

                img {                    

                    @include break-from( medium ) {
                        max-width: 420px;                                      
                    }      

                    object-fit: cover;
                }
            }

            &.featuring {
                background: $gray;
                padding-top: $spacer;

                @include break-from( medium ) {
                    @include flex-container( $align: center ); 
                }

                a {
                    color: $black;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            &.post {
                padding-bottom: $spacer;

                .entry-featured {

                    img {
                        
                        &.about {                        
                            max-width: 100%;                                                                     
                            padding: 0;                        
                        }
    
                        padding: $spacer * 2;
                    }                    
                }

                .entry-wrap { 
                    padding-left: 0;
                    text-align: center;

                    @include break-from( medium ) { 
                        text-align: left;
                    }
    

                    @include break-from( medium ) { 
                        padding-left: $spacer * 2;
                    }

                    .serves {
                        padding-top: $spacer * 1.3;

                        p {
                            margin-bottom: 0;
                        }

                        ul {
                            @include flex-container( $align: center );     
                            padding: 0;
    
                            li {    
                                list-style: none;
                                padding-right: $spacer * .5;
                                padding-left: 0;
    
                                a {
                                    color: $black;
                                    text-decoration: underline;

                                    &:hover {
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                    }                    
                }
            }

            &.ingredients {
                padding-top: $spacer * 2.5;
                padding-bottom: $spacer * 2.5;

                .entry-wrap { 
                    background: $gray;
                    padding-left: $spacer * 3;
                    padding-top: $spacer * 2;
                    padding-bottom: $spacer * 2;
                }
            }

            &.method {
                padding-top: $spacer * 3.5;
                padding-bottom: $spacer * 4.5;

                .entry-featured { 
                    margin-left: $spacer * 2;
                }
            }
        }       
    }

    .bgimage {
        min-height: 480px;
        background-size: cover;
        background-position: center;        
    }

    .video {
        padding-top: $spacer * 3.5;
        padding-bottom: $spacer * 2.5;    

        @include break-from( medium ) {
            @include flex-container( $align: stretch ); 
        }

        h2 {
            &.title {
                width: 100%;
                text-transform: uppercase;
                padding-bottom: $spacer * 2.5;      
            }
        }

        .vid {                  
            flex-grow: 12;
            padding-left: $spacer * .5;
            padding-right: $spacer * .5;

            @include break-from( medium ) {
                flex-grow: 6;
            }

            iframe {
                width: 100%;
            }
        }

        .content {
            padding-top: $spacer * 2.5;
            width: 80%;
            margin: 0 auto;
        }
    }

    .post-slider {
        padding-top: $spacer * 2.5;
        padding-bottom: $spacer * 2.5;        

        h2 {                
            padding-bottom: $spacer * 2;
        }

        .slick-slide {
            position: relative;                
    
            &:hover {
                .overlay {
                    color: $white;         
                    opacity: 1;                                
                }
            }
        } 
    
        a {                
    
            .overlay {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                height: 100%;
                width: 100%;
                opacity: 0;
                transition: .5s ease;
                background-color: rgba(155,155,155,0.8); 
                
                h2 {
                    color: $white;                            
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    line-height: 80%; 
                    -webkit-transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    text-align: center;                            
                    text-transform: uppercase;
                    font-family: 'TT-Bluescreens';
                    font-size: $h2-font-size * .8;
                    text-align: left;
                    
                    @include break-from( medium ) {
                        font-size: $h2-font-size * .7;
                    }
                }
                
                span {
                    font-size: $font-size-base * 1.2;       
                    text-transform: capitalize;            
                    font-family: Assistant; 
                }
            } 
        }
    }

} 