// ==========================================================================
// Soups Single Styles
// ==========================================================================
.content-soup {
    .content {
        article {
            padding-top: $spacer * 2.8;
            padding-bottom: $spacer * 2.3;

            @include break-from( medium ) {
                @include flex-container( $align: center );
            }

            .entry-featured,
            .entry-wrap {
                @include flex-item();      
                position: relative;
            }

            .entry-featured {
                text-align: center;
                
                img {
                    height: 280px;                  

                    @include break-from( medium ) {
                        height: 380px;                  
                    }  
                    object-fit: contain; 
                }
            } 

            .entry-wrap {
                padding-left: $spacer;

                @include break-from( medium ) {
                    padding-left: $spacer * 5.5; 
                }

                h2 {
                    text-transform: uppercase;
                }                
            }
        }

        .ingredients {             
            padding-top: $spacer * 3.5;
            padding-bottom: $spacer * 3.5; 
            background: $gray;
            margin-top: 0;

            .center {
                .col.content .text {
                    padding-left: 0;

                    @include break-from( medium ) {  
                        padding-left: $spacer * 2.2;
                    } 
                }
            }  

            .icons {
                ul {
                    @include flex-container( $align: center );
                    padding-left: 0;

                    @include break-from( medium ) { 
                        padding-left: $spacer * 3.5;
                    }

                    li {
                        width: 50%;        
                        list-style: none;
                        padding-bottom: 2em;
 
                        img { 
                            width: 160px;
                        }
                    }
                }
            }
        }
        
        .inspiration {
            padding-top: 0;
            margin-top: 0;
        }

        .information {
            padding-top: $spacer * 1.8;
            padding-bottom: $spacer * 1.8;

            table {
                font-size: $font-size-base * .8; 
            }
            .container.center {
                .content {
                    .text {
                        padding-left: 0;

                        @include break-from( medium ) {
                            padding-left: $spacer * 3;
                        }
                    }
                }
            }            
        }

        .reviews {
            background: $gray;
            margin-bottom: $spacer * 3.5;
        }

        .soup-slider {
            padding-top: $spacer * 3.5;
            padding-bottom: $spacer * 3.5;         

            h2 {                
                padding-bottom: $spacer * 2;
            }

            .slick-dots {
                display: none;
            }
        }
 
        .faq {
            padding-top: $spacer * 3;
            padding-bottom: $spacer * 5;

            h2 {
                padding-bottom: $spacer * 2; 

                &.sub-heading {                 
                    padding-bottom: $spacer;
                    text-transform: uppercase;
                    font-size: $h2-font-size  * .6;
                }
            }

            .accordion {
                padding-bottom: $spacer * 3;
                .card {
                    .btn {
                        color: $black;
                        padding: $spacer * .5;
                        font-weight: 800;
                        font-size: $font-size-base * 1.1; 
                        width: 100%;
                        text-align: left;

                        &:hover,
                        &:focus {
                            text-decoration: none;
                        }
                    }
                }

                .card-header {
                    position: relative;

                    .fa-chevron-down,
                    .fa-chevron-left {  
                        transition: opacity 0.1s, transform 0.2s linear;
                        position: absolute;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        font-size: $font-size-base * .7; 
                        color: $black;                        
                    }
                }

                .card-body {
                    padding-top: $spacer * 1.2;
                    padding-bottom: $spacer * 1.2; 
                }
            }

            .more-faq {
                padding-top: $spacer * 2;
            }
        }
    }
} 