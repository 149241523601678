// ==========================================================================
// Soups Archive Styles
// ==========================================================================

.archive-soup {    
    .header {
        padding-top: $spacer * 2.5;
        padding-bottom: $spacer * 2.5;

        .container {
            @include break-from( medium ) {
                @include flex-container( $align: center );
            }

            .col {
                @include flex-item();
                padding: 0;         
                
                h2 {
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }

    .category {        
        padding-bottom: $spacer * 2.5;

        .container {            
            @include flex-container( $align: center );             
            
            .col {
                margin-bottom: $spacer * .5;
                flex-grow: 0;

                .btn {
                    min-width: 180px;
                    text-align: center; 
                }
            }
        }
    } 

    .soups {
        padding-bottom: $spacer;

        @include break-from( medium ) { 
            padding-bottom: $spacer * 2.5;
        } 

        #spinner-ajax {
            display: none;
            text-align: center;
        }

        .container {        
            @include flex-container( $align: flex-start );
            justify-content: normal;                       

            article {
                width: 50%;
                padding: 0 0 ($spacer * .2);   

                @include break-from( medium ) { 
                    padding: 0 0 $spacer;   
                }

                
                @include break-from( medium ) { 
                    width: 33%;
                }

                .entry-featured {                     
                    position: relative;

                    img {
                        width: 100%;
                        height: 180px;
                        object-fit: contain;

                        @include break-from( medium ) { 
                            height: 280px;
                        }                      
                    }

                    &:hover {
                        .overlay {
                            opacity: 1;
                        }
                    }

                    .overlay {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        height: 100%;
                        width: 100%;
                        opacity: 0;
                        transition: .5s ease;
                        background-color: rgba(155,155,155,.5);

                        .title {
                            color: $white;                            
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            line-height: 80%; 
                            -webkit-transform: translate(-50%, -50%);
                            -ms-transform: translate(-50%, -50%);
                            transform: translate(-50%, -50%);
                            text-align: center;                            
                            text-transform: uppercase;
                            font-family: 'TT-Bluescreens';
                            font-size: $h2-font-size  * .6;
                            text-align: left;
                            
                            @include break-from( medium ) {
                                font-size: $h1-font-size * .8;
                            }
                        }
                    }
                }
            }
        }   
    }
}

.navigation {
    &.pagination {
        a {
            color: $black;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
} 