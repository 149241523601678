// ==========================================================================
// Content Styles
// ==========================================================================

.main-content {
    padding-top: $spacer * 2.5;
    padding-bottom: $spacer * 2.5;
}

.full-content {
    padding-top: $spacer * 2.5;
    padding-bottom: $spacer * 2.5;
}

.full-slider {
    padding-top: $spacer * 2.5;
    padding-bottom: $spacer * 2.5;

    h2 {
        text-transform: uppercase;
        padding-bottom: $spacer; 
    }
}

.slogan-content {
    padding-top: $spacer * 3.8;
    padding-bottom: $spacer * 3.8;
    margin-top: $spacer * 2.5;
    margin-bottom: $spacer * 2.5;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: $white;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5); 
        opacity: .7;
        z-index: 9;
    }
    
    h2 {
        text-transform: uppercase;
        padding-bottom: $spacer; 
        color: $white;
    }

    .row {
        z-index: 10;
        position: relative;
        font-size: $font-size-base * 1.3;
    }
}

.two-column-image {
    padding-top: $spacer * 2.5;
    padding-bottom: $spacer * 2.5;    

    .container { 
        @include break-from( medium ) {
            @include flex-container( $align: stretch );
        }

        .col {
            &.image {
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;        
                
                .about {
                    padding: $spacer;
                    
                    @include break-from( medium ) {
                        padding: $spacer * 3;
                    }

                    h2 {
                        text-transform: uppercase;
                    }

                    h5 {
                     padding-top: $spacer;
                    }                    
                }                    
            }

            &.map {
                padding-left: 0; 
                padding-right: 0;
 
                p {
                    padding: 0;
                    margin: 0;
                }

                iframe {
                    width: 100%;
                    min-height: 280px;

                    @include break-from( medium ) {
                        min-height: 800px;
                    }
                }
            }

            &.content {
                .about {
                    padding: $spacer;

                    @include break-from( medium ) {
                        padding: $spacer * 3;
                    }

                    h2 {
                        text-transform: uppercase;
                    }

                    h5 {
                     padding-top: $spacer;
                    }
                }                    
            }
        }
        
    }
}

.two-column {
    margin-top: $spacer * 2.5;
    margin-bottom: $spacer * 2.5;
    padding: $spacer;

    &.home {
        margin-bottom: 0;
        padding-bottom: 0 !important;
    }

    &.full {               
        @include break-from( medium ) {
            padding: 0;
        }

        .container {
            padding-top: 0;
            padding-bottom: 0;

            .col {                
                &.image-content {                    
                    background-size: cover;
                    background-repeat: no-repeat;

                    .text {
                        padding: $spacer;
                        color: $white;
                        text-align: center;

                        @include break-from( medium ) {
                            padding: $spacer * 5;
                        }

                        .btn {
                            &.btn-primary {
                                border: 1px solid $white;
                                color: $white; 

                                &:hover {
                                    border: 1px solid $black;
                                    background: $black;
                                    color: $white;
                                }
                            }
                        }
                    }
                }
            }
        }    
    }

    .container {
        &.normal {
            @include break-from( medium ) {
                @include flex-container( $align: flex-start );
            }
            
            h2 {
                z-index: 10;
                position: relative;                

                &.inline {
                    left: 1em;                    
                }
            }

            .text {
                z-index: 10; 
                position: relative;

                @include break-from( medium ) {
                    padding-left: $spacer * .5;
                }

                img { 
                    max-width: 100%;
                    height: auto;
                }

                img.stamp {
                    position: absolute;
                    width: 179px;
                    height: auto;
                    z-index: 9;
                    top: 0;
                    left: 0;

                    @include break-from( medium ) {
                        top: 2.9em;
                        left: -3.5em;
                    }
                }
            } 
        }

        &.center {
            @include break-from( medium ) {
                @include flex-container( $align: center );
            }

            .col {
                &.content {
                    .text,
                    .link {
                        @include break-from( medium ) {
                            padding-left: $spacer * .5;
                        }
                    }                    
                }
            }
        }

        &.bottom {
            @include break-from( medium ) {
                @include flex-container( $align: flex-end );
            }
        }           
         
        .col {
            @include flex-item();               

            &.content {
                padding-bottom: $spacer;

                @include break-from( medium ) {                 
                    padding-bottom: 0;
                }

                &:nth-of-type(1) {     
                    @include break-from( medium ) {                 
                        padding-right: $spacer * 2;
                        padding-left: $spacer * .5;
                    }
                }           
                
                iframe {
                    height: 100%;
                    width: 100%;
                }

                .gform_wrapper {
                    input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]) {
                        padding: .8em 4px;
                    }
                } 
            }

            &.image {
                padding-bottom: $spacer; 

                &.on-the-go {
                    img {
                        object-fit: contain;       
                        height: 480px;                    
                    }                 
                }
                
                @include break-from( medium ) {                 
                    padding-bottom: 0;
                }

                img {
                    object-fit: cover;       
                    width: 100%;                    
                }             
            }

            h2 {                
                text-transform: uppercase;     
                
                &.inline {
                    display: inline-block;
                    vertical-align: top;           
                }
            }

            .aligncenter {
                margin: 25px auto 5px $spacer;
            }

            .btn {
                margin-top: $spacer;
            }

        }
    }
}

.soup-slider {    
    padding-bottom: $spacer * 2.5;
    clear: both;

    .new-soup { 
        h2 {
            bottom: 1em;
        }
    }

    center {
        padding-top: $spacer * 2.5;
    }

    .slick-slide {
        position: relative;                

        &:hover {
            .overlay {
                color: $white;         
                opacity: 1;                                
            }
        }
    } 

    a {                

        .overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            transition: .5s ease;
            background-color: rgba(155,155,155,0.8); 
            
            h2 {
                color: $white;                            
                position: absolute;
                top: 50%;
                left: 50%;
                line-height: 80%; 
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                text-align: center;                            
                text-transform: uppercase;
                font-family: 'TT-Bluescreens';
                font-size: $h2-font-size * .8;
                text-align: left;
                
                @include break-from( medium ) {
                    font-size: $h2-font-size * .7;
                }
            }
            
            span {
                font-size: $font-size-base * 1.2;       
                text-transform: capitalize;            
                font-family: Assistant; 
            }
        } 
    }
}


// contact us
.contact-us {
    .faq {
        h2 {
            padding-bottom: $spacer * 2; 
        }
    }

    .two-column {
        .container{
            padding: 0;
            
            &.center {
                .col {

                    &:nth-of-type(1) {  
                        &.content {                            
                            padding: $spacer ($spacer * 2);
                            background: $gray;
                        }
                    }

                    &.content {
                        
                        .text {
                            padding-left: 0; 

                            p {
                                &.iframe {
                                    margin-bottom: 0;
                                }
                            }

                            iframe {
                                min-height: 580px;
                                width: 100%;
                            }
                        }
                    }                
                } 
            }   
        }
    }    
}
