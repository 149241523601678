// ==========================================================================
// Header Styles
// ==========================================================================
header { 
    background: $white;
    padding-top: $spacer * .2;
    padding-bottom: $spacer * .2;

    .branding {
        .container {
            @include flex-container( $align: center );            
            
            .logo,             
            .navbar {
                @include flex-item();            
            }

            .logo {                          
                flex-grow: 3;                 

                h2 {
                    margin: 0;
                }
            }

            .navbar {                
                flex-grow: 9;

                nav {
                    padding-right: $spacer * 3;
                }

                .sitenav {
                    display: none;

                    @include break-from( medium ) {    
                        display: block;
                    }
                }
            }
        }
    }

    .sitenav-mobile {
		display: none;
		margin-top: 0;        
        position: fixed;
        z-index: 99;
        background: $gray;
        left: 0;
        top: 0;
        width: 75%;
        height: 100%;
        padding-top: 3em;

		.nav {
			flex-direction: column;
			align-items: center;

			li {
				width: 110px;
				padding-top: $spacer / 2;
				padding-bottom: $spacer / 2;
				position: relative;

				&::after {
					content: '';
					display: block;
					height: 2px;
					width: 15%;
					background: $black;
					position: absolute;
					top: 50%;
					left: -2em;
				}

                &::marker {
                    content: " ";
                }

                &.has-sub {
                    &::after { 
                        top: 17%;
                    }
                }

				a {
					color: $black;
					font-size: 18px;
					line-height: 22px;
					border-bottom: 0;

					&:hover,
					&:focus {
						color: $black;
					}
				}

				&.active a {
					color: $black;
				}
			}
		}
	}

    .burger-menu {
		background: transparent;
		border: 0;
		padding: 0;
		line-height: 1;
		letter-spacing: 2px;

		.bars {
			position: relative;
			width: 29px;
			height: 19px;
			display: block;
			margin: auto;
		}

		.bar {
			position: absolute;
			top: 9px;
			right: 0;
			width: 65%;
			height: 2px;
			display: block;
			background-color: $black;
			border-radius: 3px;
			transform-origin: right center;
			transition: 0.3s;

			&:first-of-type,
			&:last-of-type {
				width: 100%;
			}

			&:first-of-type {
				top: 0;
			}

			&:last-of-type {
				top: 18px;
			}

			.sitenav-mobile-active & {
				opacity: 0;

				&:first-of-type,
				&:last-of-type {
					opacity: 1;
				}

				&:first-of-type {
					top: -2px;
					right: 4px;
					transform: rotate( -45deg );
				}

				&:last-of-type {
					top: 19px;
					right: 4px;
					transform: rotate( 45deg );
				}
			}
		}

		@include break-from( medium ) {
			display: none;
		}

		.text {
			font-weight: $font-weight-bolder;
			font-size: 9px;
			line-height: 24px;
			text-transform: uppercase;
			display: inline-block;
			margin-bottom: -9px;
		}
	}
}

.banner {    
    &.full_banner {        
        position: relative;
        min-height: 380px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        @include break-from( xxsmall ) {             
            min-height: 110px;            
        }

        @include break-from( xsmall  ) {             
            min-height: 190px;            
        }

        @include break-from( small ) {             
            min-height: 230px;            
        }

        @include break-from( large ) {             
            min-height: 360px;         
        }

        @include break-from( xlarge ) {             
            min-height: 390px;
        }

        @include break-from( xxlarge ) {             
            min-height: 480px;
        }

        &.on-the-go {
            &:after { 
                background: none !important;     
            }    
        }

        /*&:after {
           content: "";
           width: 100%;
           height: 200px;
           background: url("../images/banner-gradient.png") repeat center;
           bottom: 0;
           right: 0;
           position: absolute;
           display: inline-block;
        }*/

        .cols {            
            &:nth-of-type(1) { 
                width: 100%;

                @include break-from( medium ) { 
                    width: 65%;
                }

            }

            &:nth-of-type(2) { 
                width: 100%;

                @include break-from( medium ) { 
                    width: 35%;
                }
            }
        }
        .text {

            &.bottom {
                position: absolute;
                bottom: 0;
                text-align: center;
                left: 0;
                right: 0;                                       
                z-index: 10;         
                padding: 0;

                @include break-from( medium ) {
                    padding: $spacer;
                }
            }            

            h1, h2, h3 {
                color: $white;
                padding: $spacer 0 0;
                margin: 0;
                text-transform: uppercase;
                font-size: $h1-font-size * .45;                

                @include break-from( medium ) {
                    font-size: $h1-font-size * 1.4; 
                }
            }
        }

        ul {        
            padding: 0;
            
            @include break-from( medium ) {
                text-align: right;                
            }

            li {
                display: inline-block;
                list-style: none;
                
                img {
                    width: 80px; 

                    @include break-from( medium ) {
                        width: 125px; 
                    }
                    object-fit: cover;
                    padding: 0 ($spacer * .5);
                }
            }
        }
    }    
    
    &.home {
        .container {
            padding-top: 0;
            padding-bottom: 0;    

            .left,              
            .right {
                @include flex-item();       
            }

            .left {
                width: 100%;
                padding-left: 0;                

                @include break-from( medium ) { 
                    width: 45%;
                }                

                @include break-from( medium ) {
                    padding-left: $spacer * .7;
                }

                .content {        
                    color: $white;                    
                    position: relative;
                    text-align: center;

                    @include break-from( medium ) { 
                        padding-right: $spacer * 2;  
                    }

                    h1, 
                    h2, 
                    h3 {
                        color: $white;
                        padding: 0;
                        margin: 0;
                        text-transform: uppercase;
                        font-size: $h1-font-size * .6;                                
                        text-align: center;       
                        padding: $spacer * .5;

                        br {
                            display: none;                            
                        }
            
                        @include break-from( medium ) {
                            font-size: $h1-font-size * 1.6; 
                            text-align: left;                             
                            padding: inherit;

                            br {
                                content: "";
                            }
                        }                        
                    }

                    img.banner-stamp {
                        width: 140px;                        
                        
                        @include break-from( medium ) {
                            position: absolute;
                            right: 7em;
                            bottom: -1em;
                        }
                    }
                }
            }

            .right {
                width: 100%;        
                padding-top: $spacer;        
                padding-bottom: $spacer;        

                @include break-from( medium ) {
                    width: 55%;
                    padding-top: inherit;        
                    padding-bottom: inherit;        
                }

                .image-rotate { 
                    width: 100%;
                    margin: 0;

                    .slick-dots {
                        bottom: 0;

                        li {
                            &.slick-active {
                                button::before {
                                    color: $white;
                                }
                            }

                            button::before {
                                color: $black;
                            }
                        }
                    }
                    
                    .slick-prev {
                        left: 33px;

                        &:before {
                            background-image: url("../images/left-chevron-white.svg");    
                        }

                        @include break-from( medium ) {  
                            left: -7px;
                        }
                    }

                    .slick-next {
                        right: -15px;

                        &:before {                            
                            background-image: url("../images/right-chevron-white.svg");    
                        }

                        @include break-from( medium ) {
                            right: -70px;
                        }
                    }
                }

                img {
                    width: 100%;
                    height: 325px;
                    
                    @include break-from( medium ) {
                        height: 380px; 
                        object-fit: cover;
                    } 

                    object-fit: contain;                    
                    padding: 0 $spacer;
                }        
            }
        }    
    }

    .container {
        padding-top: $spacer;
        padding-bottom: $spacer;        

        @include break-from( medium ) {            
            padding-top: 0;
            padding-bottom: 0;
            @include flex-container( $align: center );        
        }        
    }
}
